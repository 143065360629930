import { IntegrationBaseInterface } from '../integration-base.interface';
import { SitemapUrlEntryInterface } from './sitemap-integration-entry.interface';

export interface SitemapScanRequestInterface {
	url: string;
}

export interface SitemapRegisterRequestInterface {
	sitemap: string;

	urls: SitemapUrlEntryInterface[];
}

export interface SitemapSelectionRequestInterface {
	integrationId: IntegrationBaseInterface['id'];
}
