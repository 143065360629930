import { SearchParams } from '../search';

export interface ChatKnowledgeChatInterface {
	id: string;
	name: string;
	query: Required<SearchParams>;
	origin: ChatKnowledgeChatOriginEnum;
	useMemory: boolean;
	createdAt: number;
	updatedAt: number;
	lastUsedAt: number;
	integrationIds: Array<string>;
}

export enum ChatKnowledgeChatOriginEnum {
	DEFAULT = 'DEFAULT',
	SEARCH = 'SEARCH',
	NODE = 'NODE',
}
