import { BooleanEnum, Nullable } from '../typescript';
import { AlbumInterface } from '../albums';
import { CustomPropertyInterface } from '../custom-property';
import { AnyNode, GetNodeResponse, NodeType } from '../nodes';
import { UserPublicInfo } from '../user';
import { NodesFilter } from './nodes-filter.type';
import { SearchAttributeFilter } from './search-attribute.type';
import { SearchPropertyFilter } from './search-property.type';
import { NodeChunkContentInterface } from '../chat';

export type RequestCanvasValidColumnNodeType = Exclude<NodeType, NodeType.WORKSPACE>;

export type SearchResponse<B extends BooleanEnum> = B extends BooleanEnum.YES
	? SearchResponseWithCount<SearchIdsResponse>
	: SearchResponseWithCount<SearchNodeResponse>;

export type SearchResponseWithCount<T extends Record<string, unknown>> = {
	nodes: T[];
	count: number;
	searchType: SearchType;
	nlpSearchParams?: SearchParams;
};

export type SearchNodeResponse<T = AnyNode> = Omit<
	GetNodeResponse<T>,
	'content' | 'noteContent' | 'htmlContent' | 'containedFiles' | 'attachments'
> & {
	highlight?: HighlightItem;
	parentAlbum?: Omit<GetNodeResponse<SearchNodeResponse<AlbumInterface>>, 'content'>[];
	trimmedContent?: string;
	score: number;
	chunkContent?: NodeChunkContentInterface;
};

export enum SearchType {
	SEMANTIC = 'SEMANTIC',
	NLP = 'NLP',
	NORMAL = 'NORMAL',
}

export enum SearchAITypeEnum {
	SEMANTIC = 'SEMANTIC',
	NLP = 'NLP',
	NONE = 'NONE',
	AUTO = 'AUTO',
}

export type SearchIdsResponse = {
	id: string;
	score: number;
	parentId: Nullable<string>;
	subIds: Nullable<string[]>;
	domain: Nullable<string>;
	nodeType: NodeType;
	highlight: HighlightItem;
	workspaceIds: string[];
	relatedNodesNumber: number;
	trimmedContent?: string;
};

export type CanvasSearchResponse = {
	nodes: { [id: string]: SearchNodeResponse };
	relevantMap: { relevantNode: string; queryRelated: string[]; otherRelated: string[] }[];
	ids: string[];
};

export type NodeTypeFilters = Exclude<NodeType, NodeType.WORKSPACE>;

export interface SearchParams {
	query?: string;
	nodeTypes?: NodeTypeFilters[];
	propertyFilters?: SearchPropertyFilter[];
	attributeFilters?: SearchAttributeFilter[];
	nodesFilters?: NodesFilter[];
	fuzzy?: boolean; // Only feature flags
	andOr?: 'and' | 'or'; // Only feature flags
	workspaceIds?: string[] | 'all';
	unassigned?: boolean;
}

export interface SearchAIParams {
	searchAIType?: Exclude<SearchAITypeEnum, SearchAITypeEnum.NLP>;
}

export interface SearchReturnTypeParams {
	searchAll?: boolean;
	returnOnlyIds?: BooleanEnum;
}

export interface ColumnNodeType {
	columnNodeType: Exclude<NodeType, NodeType.WORKSPACE>;
}

export interface HighlightItem {
	name?: string;
	description?: string;
	comments?: { value: string; author: UserPublicInfo };
	customProperties?: { value: string; customProperty: CustomPropertyInterface };
	content?: string;
}
