export enum ChatMessageStatusEnum {
	SENDING = 'SENDING',
	WRITING = 'WRITING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

export enum ChatTypeEnum {
	WIDGET = 'WIDGET',
	KNOWLEDGE = 'KNOWLEDGE',
}
