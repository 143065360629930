import { SourceEnum } from '../nodes';
import { LocationLatLon } from '../location';
import { RemindersToUpdate } from '../reminders';

export enum CustomPropertyDatatype {
	UNIT_NUMBER = 'UNIT_NUMBER',
	NUMBER = 'NUMBER',
	STRING = 'STRING',
	SELECT = 'SELECT',
	MULTI_SELECT = 'MULTI_SELECT',
	BOOLEAN = 'BOOLEAN',
	MULTI_STRING = 'MULTI_STRING',
	GEOLOCATION = 'GEOLOCATION',
	TIMESTAMP = 'TIMESTAMP',
	PROCESS = 'PROCESS',
}

export enum CustomPropertyType {
	PLAIN_TEXT = 'PLAIN_TEXT',
	PLAIN_NUMBER = 'PLAIN_NUMBER',
	SELECT_TAG = 'SELECT_TAG',
	DATE = 'DATE',
	TIME = 'TIME',
	MULTI_SELECT_TAG = 'MULTI_SELECT_TAG',
	MULTI_SELECT_SOURCE = 'MULTI_SELECT_SOURCE',
	SELECT_STAR = 'SELECT_STAR',
	PHONE = 'PHONE',
	EMAIL = 'EMAIL',
	URL = 'URL',
	NAME = 'NAME',
	CURRENCY = 'CURRENCY',
	BOOLEAN = 'BOOLEAN',
	MEASURE_WEIGHT = 'MEASURE_WEIGHT',
	MEASURE_DISTANCE = 'MEASURE_DISTANCE',
	SELECT_IMPORTANCE = 'SELECT_IMPORTANCE',
	PERCENTAGE = 'PERCENTAGE',
	LOCATION = 'LOCATION',
	USER = 'USER',
	PROCESS = 'PROCESS',
}

export enum CustomPropertyDateRepeatsEnum {
	NEVER = 'NEVER',
	YEARLY = 'YEARLY',
	MONTHLY = 'MONTHLY',
	WEEKLY = 'WEEKLY',
	DAILY = 'DAILY',
}

export interface LocationPropertyValue {
	location: LocationLatLon;
	locationString?: string;
	locationPrecision?: number;
}

export enum ProcessPropertyStatusEnum {
	PENDING = 'PENDING',
	IN_PROGRESS = 'IN_PROGRESS',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
}

export type CustomPropertyValueType =
	| boolean
	| string
	| number
	| string[]
	| number[]
	| LocationPropertyValue
	| ProcessPropertyStatusEnum;

export interface CustomPropertyValue {
	id: string;
	value: CustomPropertyValueType;
	visible: boolean;
	lastModifiedBy: CustomPropertyLastModifiedBy;
	createdAt: number;
	updatedAt: number;
	// UNIT NUMBER
	unit?: UnitOptionType;
	// DATE WITH REMINDER
	reminders?: string[];
	repeat?: CustomPropertyDateRepeatsEnum;
	timezone?: string;
	allDay?: boolean;
	// PROCESS
	lastSuccess?: number;
}

export interface CustomPropertyInterface {
	id: string;
	name: string;
	datatype: CustomPropertyDatatype;
	type: CustomPropertyType;
	options?: string[];
	permissions: CustomPropertyPermissions[];
}

export interface CustomProperty<KEY = CustomPropertyInterface, VALUE = CustomPropertyValue> {
	key: KEY;
	value: VALUE;
}

export enum CustomPropertyPermissions {
	/**
	 * The user can remove the property completely
	 * (remember that the name can always be changed)
	 */
	PROPERTY_ACCESS = 'PROPERTY_ACCESS',
	/**
	 * The user can edit the value (and even delete it from the node)
	 */
	VALUE_ACCESS = 'VALUE_ACCESS',
}

export type UnitOptionType =
	| CurrencyUnitOptionsEnum
	| MeasureWeightUnitOptionsEnum
	| MeasureDistanceUnitOptionsEnum;

export enum CurrencyUnitOptionsEnum {
	ANY = 'ANY',
	EURO = 'EURO',
	USA_DOLLAR = 'USA_DOLLAR',
	POUND = 'POUND',
	JAPANESE_YEN = 'JAPANESE_YEN',
	SWISS_FRANC = 'SWISS_FRANC',
	CANADIAN_DOLLAR = 'CANADIAN_DOLLAR',
	CHINESE_YUAN = 'CHINESE_YUAN',
}

export enum MeasureWeightUnitOptionsEnum {
	KILOGRAM = 'KILOGRAM',
	GRAM = 'GRAM',
	MILLIGRAM = 'MILLIGRAM',
}

export enum MeasureDistanceUnitOptionsEnum {
	KILOMETER = 'KILOMETER',
	METER = 'METER',
	CENTIMETER = 'CENTIMETER',
	MILLIMETER = 'MILLIMETER',
}

export const UnitOptionValues = new Set([
	...Object.values(CurrencyUnitOptionsEnum),
	...Object.values(MeasureDistanceUnitOptionsEnum),
	...Object.values(MeasureWeightUnitOptionsEnum),
]) as Set<UnitOptionType>;

export const userDefinedOptionsType = new Set([
	CustomPropertyType.SELECT_TAG,
	CustomPropertyType.MULTI_SELECT_TAG,
]);

export const customPropertiesReachOptionsFromType: {
	[key in CustomPropertyType]: string[] | undefined;
} = {
	[CustomPropertyType.PLAIN_TEXT]: undefined,
	[CustomPropertyType.PLAIN_NUMBER]: undefined,
	[CustomPropertyType.SELECT_TAG]: undefined,
	[CustomPropertyType.DATE]: undefined,
	[CustomPropertyType.TIME]: undefined,
	[CustomPropertyType.MULTI_SELECT_TAG]: undefined,
	[CustomPropertyType.MULTI_SELECT_SOURCE]: Object.values(SourceEnum),
	[CustomPropertyType.SELECT_STAR]: ['0', '1', '2', '3', '4', '5'],
	[CustomPropertyType.PHONE]: undefined,
	[CustomPropertyType.EMAIL]: undefined,
	[CustomPropertyType.URL]: undefined,
	[CustomPropertyType.NAME]: undefined,
	[CustomPropertyType.CURRENCY]: undefined,
	[CustomPropertyType.BOOLEAN]: undefined,
	[CustomPropertyType.MEASURE_WEIGHT]: undefined,
	[CustomPropertyType.MEASURE_DISTANCE]: undefined,
	[CustomPropertyType.SELECT_IMPORTANCE]: [
		'0',
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
	],
	[CustomPropertyType.PERCENTAGE]: undefined,
	[CustomPropertyType.LOCATION]: undefined,
	[CustomPropertyType.USER]: undefined,
	[CustomPropertyType.PROCESS]: undefined,
};

export enum CustomPropertyLastModifiedBy {
	REACH = 'REACH',
	USER = 'USER',
	WIKIMEDIA = 'WIKIMEDIA',
	POCKET = 'POCKET',
	REACH_VISION = 'REACH_VISION',
	IMPORT_CONTACTS = 'IMPORT_CONTACTS',
	IMPORT_CSV = 'IMPORT_CSV',
	ZAPIER = 'ZAPIER',
	REACH_TRANSCRIBER = 'REACH_TRANSCRIBER',
	MAP_PINS = 'MAP_PINS',
	IMPORT_EVERNOTE = 'IMPORT_EVERNOTE',
	GOOGLE_DOCS = 'GOOGLE_DOCS',
	DROPBOX_PAPER = 'DROPBOX_PAPER',
	QUIP = 'QUIP',
	BROWSER_BOOKMARKS = 'BROWSER_BOOKMARKS',
	GOOGLE_DRIVE = 'GOOGLE_DRIVE',
	SITEMAP = 'SITEMAP',
}

export interface PropertyModified {
	value: CustomPropertyValue;
	updatedReminders?: RemindersToUpdate;
	options?: string[];
}
