import { QuotaTypeEnum } from './quota-type.enum';
import { QuotaModeEnum } from './quota-mode.enum';

export const quotaTypeModes = {
	[QuotaTypeEnum.NODES]: QuotaModeEnum.CONSUMABLE,
	[QuotaTypeEnum.STORAGE]: QuotaModeEnum.CONSUMABLE,
	[QuotaTypeEnum.CHAT_KNOWLEDGE_CONVERSATIONS]: QuotaModeEnum.CONSUMABLE,
	[QuotaTypeEnum.MONTHLY_CHAT_KNOWLEDGE_MESSAGES]: QuotaModeEnum.CONSUMABLE,
	[QuotaTypeEnum.FILE_UPLOAD_LIMIT_IN_BYTES]: QuotaModeEnum.ALLOCATED,
	[QuotaTypeEnum.CHAT_WIDGETS]: QuotaModeEnum.CONSUMABLE,
	[QuotaTypeEnum.MONTHLY_CHAT_WIDGET_MESSAGES]: QuotaModeEnum.CONSUMABLE,
	[QuotaTypeEnum.INTEGRATION_NODES]: QuotaModeEnum.CONSUMABLE,
} satisfies Record<QuotaTypeEnum, QuotaModeEnum>;
