import { SearchParams } from '../search';
import { Nullable } from '../typescript';
import { ChatWidgetIconSizeEnum } from './chat-widget-icon-size.enum';
import { ChatWidgetSystemPayloadInterface } from './chat-widget-system-action.types';
import { ChatWidgetSystemFrequencyEnum } from './chat-widget-system-frequency.enum';
import { ChatWidgetSystemTriggerEnum } from './chat-widget-system-trigger.enum';
export enum ChatWidgetPageLocationEnum {
	BOTTOM_RIGHT = 'BOTTOM_RIGHT',
	BOTTOM_LEFT = 'BOTTOM_LEFT',
}

export interface ChatWidgetCategoryInterface {
	name: string;
	description: string;
	colour: number[];
	action?: Nullable<ChatWidgetSystemActionInterface>;
}

export interface ChatWidgetSystemActionInterface {
	trigger: ChatWidgetSystemTriggerEnum;
	content: string;
	active: boolean;
	frequencyType: ChatWidgetSystemFrequencyEnum;
	frequency: number;
	payload: ChatWidgetSystemPayloadInterface;
}

export interface ChatWidgetUtmInterface {
	utmSource?: Nullable<string>;
	utmMedium?: Nullable<string>;
	utmCampaign?: Nullable<string>;
	utmTerm?: Nullable<string>;
	utmContent: boolean;
}
export interface ChatWidgetInterface {
	id: string;
	createdAt: number;
	updatedAt: number;
	lastUsedAt: Nullable<number>;
	settings: ChatWidgetSettingsInterface;
	customization: ChatWidgetCustomizationInterface;
	integrationIds: Array<string>;
}

export interface ChatWidgetSettingsInterface {
	name: string;
	active: boolean;
	query: Required<SearchParams>;
	instructions: Nullable<string>;
	showWebsites: boolean;
	showFiles: boolean;
	contactEmail: string;
	whitelist: string[];
	categories: ChatWidgetCategoryInterface[];
	utm: ChatWidgetUtmInterface;
}

export interface ChatWidgetCustomizationInterface {
	logoImageUrl: Nullable<string>;
	logoThumbnailUrl: Nullable<string>;
	iconImageUrl: Nullable<string>;
	iconThumbnailUrl: Nullable<string>;
	assistantName?: Nullable<string>;
	title?: Nullable<string>;
	welcomeMessage: string[];
	colours: ChatWidgetCustomizationColoursInterface[];
	defaultQuestions: string[];
	inputPlaceholder?: Nullable<string>;
	popUpMessage?: Nullable<string>;
	errorMessage?: Nullable<string>;
	pageLocation?: ChatWidgetPageLocationEnum;
	clearText?: Nullable<string>;
	contactText?: Nullable<string>;
	sendText?: Nullable<string>;
	popupMessageDisabled: boolean;
	iconSize: ChatWidgetIconSizeEnum;
}

export interface ChatWidgetCustomizationColoursInterface {
	name: string;
	primary: Nullable<number[]>;
	hover: Nullable<number[]>;
	mainText: Nullable<number[]>;
	secondaryText: Nullable<number[]>;
	background: Nullable<number[]>;
	secondaryBackground: Nullable<number[]>;
	logoBackground: Nullable<number[]>;
	line: Nullable<number[]>;
	iconColour: Nullable<number[]>;
	error: Nullable<number[]>;
}
